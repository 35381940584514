<template>
  <v-container class="px-0">
    <v-row>
      <v-col class="text-left">
        <h2 class="text-h4" style="line-height: 1.2">
          <span class="barlow font-weight-bold">Bem-vindo(a), Admin!</span>
        </h2>
      </v-col>
    </v-row>
    <div class="text-left">
      <h6 class="pt-2 pb-6 text-subtitle-1" style="line-height: 1.3">
        <span class="barlow font-weight-light">Escoha a opção abaixo</span>
      </h6>
    </div>
    <v-row>
      <v-col sm="4">
        <v-sheet rounded outlined color="primary">
          <v-card to="/admin/usuarios">
            <v-responsive aspect-ratio="1" class="flex justify-center align-center">
              <v-icon size="72" circle color="primary">mdi-account-group</v-icon>
              <v-card-text class="pt-0 text-center primary--text text-h6">
                USUÁRIOS
              </v-card-text>
            </v-responsive>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import api from '@/api/api.js'
import { mapActions } from 'vuex'
//import HleFormHoteis from '@/components/Hoteis/FormHoteis'

export default {
  name: 'HomeView',
  components: {
  },
  data: () => ({
    hotel: 'teju-acu.jpg',
    form: {}
  }),
  methods: {
    ...mapActions([
      //'carregarHoteisAction'
    ]),
    getImg(i) {
      return require('@/assets/images/hoteis/'+i)
    },
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = 'BSB'
    // this.form.checkin = '2022-06-28'
    // this.form.checkout = '2022-06-30'
    // this.form.adultos = '1'
    // this.form.criancas = '0'


    //this.carregarHoteisAction(this.form)
    //this.carregarHoteisAction()

  }
}
</script>
