<template>
  <div class="">
    <v-row class="px-0">
      <v-col
        md="12"
        class="px-0"
      >
        <div
          class="pb-2 px-0 mx-0 text-left"
        >
          <v-card
            flat
            class="px-0 mx-0 white grey--text text--darken-4 text-left"
          >
            <v-card-text class="pt-2 px-0">
              <div class="text-h5 font-weight-bold" style="line-height: 1.3">
                <span class="grey--text text--darken-4">Minhas Reservas</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  <!-- <div
    v-if="hoteis != null"
    class=""
  > -->
    <!-- <v-row no-gutters>
      <v-col class="pb-6">
        <span class="caption">1 hotel encontrado</span>
        <div>FILTROBUSCA: <br>{{ filtroBusca }}</div>
        <div>HOTÉIS: <br>{{ hoteis }}</div>
      </v-col>
    </v-row> -->
    <v-row v-if="reservas" class="px-0">
      <v-col
        md="12"
        class="px-0"
      >
        <div
          class="pb-2 px-0 mx-0 text-left"
        >
          <v-data-table
            :headers="headers"
            :items="reservas"
            :items-per-page="5"
            class="elevation-0"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                :color="getColorStatus(item.status)"
                dark
                small
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{  }">
              <v-icon
                small
                class="mr-2"
              >
                mdi-card-account-details
              </v-icon>
            </template>
          </v-data-table>

        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
//import moment from 'moment'

export default {
  name: 'ListaQuartos',
  components: {

  },
  props: [
    'reservas'
  ],
  data: () => ({
    headers: [
      { text: 'Nº Reserva', align: 'start', sortable: false, value: 'idReserva', },
      { text: 'Data da Reserva', value: 'dataReserva' },
      { text: 'Status', value: 'status' },
      { text: 'Hotel', value: 'hotel' },
      { text: 'Destino', value: 'destino' },
      { text: 'Check-in', value: 'checkin' },
      { text: 'Check-out', value: 'checkout' },
      //{ text: 'Viajantes', value: 'viajantes' },
      { text: 'Valor', value: 'valor' },
      { text: '', value: 'actions', sortable: false },
    ],
    historico: [
      // {
      //   name: '#4819250',
      //   status: 'CONFIRMADA',
      //   hotel: 'HERITAGE COMFORT INN',
      //   cidade: 'São Paulo, SP - Brasil',
      //   checkIn: '12/04/2022',
      //   checkOut: '14/04/2021',
      //   //viajantes: 'JHOE DOE',
      //   valor: 'R$ 545,34'
      // },
      {
        name: '#4819251',
        status: 'CANCELADA',
        hotel: 'Sia Park Executive Hotel',
        destino: 'Brasília, Distrito Federal, Brasil',
        quarto: 'Single Suite',
        checkIn: '28/06/2022',
        checkOut: '30/06/2022',
        //viajantes: 'FOO BAR',
        valor: 'R$ 2.431,36'
      },
    ],
  }),

  computed: {
    ...mapState({
      carregandoReservas: state => state.carregandoReservas,
      //reservas: state => state.reservas,
    })
  },

  watch: {

  },

  methods: {
    ...mapActions([
      'carregarReservasAction'
    ]),
    getColorStatus (status) {
      if (status === 'CONFIRMADA') {
        return 'green'
      }
      return 'gray'
    }
  },

  created () {
    //this.carregarReservasAction(this.form)
    console.log('RESERVES RESERVAS >>> ', this.reservas)
  }

}
</script>
