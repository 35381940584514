<template>
  <v-app>
    <v-main>
      <!-- <v-container
        class="fill-height"
        fluid
        style="background-image: linear-gradient(to top, rgba(208, 0, 110, 0.6),rgba(152, 237, 0, 0.4));"
      > -->
      <v-container
        class="fill-height"
        fluid
        style="background-image: linear-gradient(to top, rgba(255, 255, 255, 0.6),rgba(208, 0, 110, 0.9));"
      >
        <v-row class="justify-center">
          <v-card class="elevation-1 card-login">
            <div class="card-title-hole">
              <!-- <h1>Logo Holé</h1> -->
              <div>
                <v-img
                  lazy-src="../../assets/images/logo-hole.png"
                  max-height="53"
                  max-width="120"
                  src="../../assets/images/logo-hole.png"
                ></v-img>
                <!-- <v-img
                  lazy-src="../../assets/images/logo_vivermais_rgb_fundobranco.png"
                  max-height="53"
                  max-width="120"
                  src="../../assets/images/logo_vivermais_rgb_fundobranco.png"
                ></v-img> -->
              </div>
            </div>
            <v-card-text class="pt-2 pb-0">
              <strong class="text-area-cliente pt-6">Área do Cliente</strong>
            </v-card-text>
            <v-card-text>
              <div>
                <v-alert
                  v-model="alert"
                  dismissible
                  close-icon="mdi-close"
                  color="red"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="mdi-information-outline"
                >
                  <strong>E-mail</strong> ou <strong>senha</strong> incorreto.
                </v-alert>
              </div>
              <v-form
                v-model="valid"
                @submit.prevent="loginSubmit"
                ref="form"
                lazy-validation
              >
                <v-col lg="12" md="12" sm="12">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>
                </v-col>
                <v-col lg="12" md="12" sm="12">
                  <v-text-field
                    v-model="senha"
                    :rules="senhaRules"
                    :type="show ? 'text' : 'password'"
                    label="Senha"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    required
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
                <v-col lg="12" md="12" sm="12">
                  <v-btn
                    type="submit"
                    class="ma-2 color-main-button white--text"
                    block
                    large
                    :loading="loading"
                    :disabled="loading"
                  >
                    Entrar
                  </v-btn>

                  <v-col>
                    <!-- Register new user -->

                    Não tem cadastro?

                    <div>
                      <router-link to="/cadastro">
                        <a>Cadastre-se</a>
                      </router-link>
                    </div>
                    <!-- Recovery password -->
                    <!-- <div>
                      <router-link to="/recuperar-senha">
                        <a>Esqueci minha senha</a>
                      </router-link>
                    </div> -->
                  </v-col>
                </v-col>
              </v-form>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="css" scoped>
.card-login {
  width: 400px;
  padding: 30px 45px;
}
.text-card-align {
  font-weight: 900;
  margin-left: 65px;
}
.card-title-hole {
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.text-area-cliente {
  font-weight: 700;
  font-style: normal;
  font-size: 22px;
  color: #052449;
  line-height: 40px;
  margin-top: 5px;
  margin-bottom: 20px;
}
.color-main-button {
  background-color: rgba(208, 0, 110, 1) !important;
  font-weight: 600;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
//import axios from 'axios'
//import api from '@/api/api.js'
import qs from 'qs'

export default {
  data: () => ({
    valid: false,
    email: "andre@hibrit.com.br",
    senha: "vip123",
    // email: "",
    // senha: "",
    show: false,
    loading: false,
    alert: false,
    emailRules: [
      (v) => !!v || "E-mail é um campo obrigatório!",
      (v) => /.+@.+/.test(v) || "O e-mail deve ser válido!",
    ],
    senhaRules: [(v) => !!v || "Senha é um campo obrigatório!"],
  }),

  computed: {
    ...mapState({
      loggingIn: 'user/loggingIn',
      loginError: 'user/loginError',
      accessToken: 'user/accessToken'
    })
  },

  methods: {
    ...mapActions({
      doLogin: 'user/doLogin'
    }),

    //async login
    loginSubmit() {
      //this.loading = true

      let credenciais = qs.stringify({
        email: this.email,
        senha: this.senha
      })

      if(this.$refs.form.validate()) {
        this.$store.dispatch('user/doLogin', credenciais).then(
          user => {
            console.log('user',user)
            this.$router.push({ path: 'home' })
          },
          error => {
            this.loading = false
            console.log('LoginView ERROR >>> ',error)
            // console.log('loginERror', this.loginError)
            // (error.response && error.response.data) ||
            //     error.message ||
            //     error.toString();
            this.$router.push({ path: '/' })
          }
        )
      }
    },
  },
}
</script>