<template>
  <v-container class="pt-0 mt-0 ml-0 text-left">

    <div class="text-center d-inline text-h6 justify-center pl-0 ml-0">
      <v-btn text icon class="text-left text-start pl-0 ml-0" @click="voltar">
        <v-icon class="text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
      <!-- <div class="text-h6 d-inline ">
        <span>Processando...</span>
      </div> -->
    </div>

    <v-row class="pt-4 text-center justify-center">
      <v-col class="" cols="6">
        <v-card class="pa-4">
          <v-card-text class="pt-2 px-0">
            <div class="text-h5 font-weight-bold" style="line-height: 1.3">
              <v-icon color="primary" large >mdi-progress-clock</v-icon>
              <span class="grey--text text--darken-4 pl-2">Sua reserva está sendo processada...</span>
            </div>
            <div class="text-overline font-weight-regular">
              <span class="">Acompanhe o resultado da reserva em sua lista de reservas</span>
            </div>
          </v-card-text>
          <v-card-action>
            <v-btn
              large
              color="primary"
              class="mr-4 text-large"
              to="/minhas-reservas"
            >
              Ver Lista de Reservas
            </v-btn>
          </v-card-action>
        </v-card>
      </v-col>

    </v-row>


     <!-- <div v-else-if="hotel">
      <ReservaForm :hotelId="hotelId" :quartoId="quartoId" :quartoDescricao="quartoDescricao" />
    </div> -->
  </v-container>
</template>


<script>
import { mapState, mapActions } from 'vuex'
//import moment from 'moment'

export default {
  components: {
    //HospedeResponsavel,
  },
  props: [
    // 'hotelId',
    // 'quartoId',
    // 'quartoDescricao'
//    'quarto',
    'resutado'
  ],

  methods:{
    ...mapActions({
      carregarUsuarioeDependentesAction: 'hotel/carregarUsuarioeDependentesAction'
    }),
    voltar() {
      this.$router.go(-1)
    }
  },

  data: () => ({
    usuarios: null
  }),

  computed: {
    ...mapState({
      // filtroBusca: state => state.filtroBusca,
      hotel: state => state.hotel.hotel,
      userData: state => state.user.userData,
      accessToken: state => state.user.accessToken,
      // hoteis: state => state.hoteis,
      //hotel: state => state.hoteis[this.hotelId]
    }),

    // checkout(){
    //   if(this.$route.params.checkin){
    //     moment.locale('pt-br')
    //     return moment(new Date(this.$route.params.checkin)).add(1,'day').format('D MMM')
    //   }
    //   return null
    // },
  },

  created () {
    console.log('RESULTADO',this.resultado)
    // this.$router.push({name: 'HomeView'})
    // if(this.hotel == null) {
    //   //this.$router.push({path: '/home'})
    //   console.log('hotelzzz home >>>> ',this.hotel)
    //   this.$router.push({name: 'HomeView'})
    // }
    // else {
    //   console.log('hotel >>>> ',this.hotel)
    // }

    // this.carregarUsuarioeDependentesAction(this.userData.sucesso.id)
    //   .then(function(ret) {
    //     this.usuarios = ret
    //   })
    //this.usuarios = this.carregarUsuarioeDependentesAction(this.userData.sucesso.id)
    console.log("Echo Token", this.$route.params.echoToken)
    console.log("Hotel Code", this.$route.params.hotelCode)
    console.log("Hotel Signature", this.$route.params.hotelSignature)
    console.log("Hotel", this.hotel)
    console.log("Quarto", this.quarto)
  }
}
</script>