<template>
<v-container class="fill-height" fluid>
    <v-row
        justify="center"
    >
        <v-card class="elevation-1 card-login">
            <div class="card-title-hole">
                <div>
                    <v-img
                        lazy-src="../../assets/images/hole-logo.png"
                        max-height="53"
                        max-width="120"
                        src="../../assets/images/hole-logo.png"
                    ></v-img>
                </div>
                <strong class="text-area-cliente">RECUPERAR SENHA</strong>
            </div>
            <v-card-text>
                <div>
                    <v-alert
                        v-model="alert"
                        dismissible
                        close-icon="mdi-close"
                        :color="status ? 'green' : 'red'"
                        border="left"
                        elevation="2"
                        colored-border
                        :icon="status ? 'mdi-check' : 'mdi-information-outline'"
                    >
                        {{ message }}
                    </v-alert> 
                </div>
                <v-form v-model="valid" @submit.prevent="recuperar" ref="form" lazy-validation>
                    <v-col lg="12" md="12" sm="12">
                    <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                    ></v-text-field>
                    </v-col>
                    <v-col lg="12" md="12" sm="12">
                        <v-btn 
                            type="submit"
                            class="ma-2 color-main-button white--text"
                            block
                            large
                            :loading="loading"
                            :disabled="loading"
                        >
                            Recuperar
                        </v-btn>
                        <!-- Go back to login -->
                        <router-link
                            to="/login"
                        >
                            <a>Voltar para o login</a>
                        </router-link>
                    </v-col>
                </v-form>
            </v-card-text>
        </v-card>
    </v-row>
</v-container>
</template>
<script>
import { HTTP } from '../../api/http-common'
export default {
    name: 'RecoveryPass',
        data: () => ({
        valid: false,
        email: '',
        loading: false,
        alert: false,
        status: false,
        message: '',
        emailRules: [
            v => !!v || 'E-mail é um campo obrigatório!',
            v => /.+@.+/.test(v) || 'O e-mail deve ser válido!'
        ]
    }),
    methods: {
        recuperar () {
            if (this.$refs.form.validate() === false) {
                return
            }
            this.loading = true
            let formData = {
                email: this.email,
            }
            HTTP.post('password-reset/create', formData).then(res => {
                if (res.status === 200) {
                    this.alert = true
                    this.status = true
                    this.message = res.data.message
                }
                this.loading = false
            }).catch(error => {
                console.error('Error -', error)
                if (error.response.status === 404) {
                    this.alert = true
                    this.status = false
                    this.message = 'E-mail não encontrado!'
                }
                this.loading = false
            })
        }
    }
}
</script>
<style lang="css" scoped>
    .card-login {
        width: 400px;
        padding: 30px 45px;
    }
    .text-card-align {
        font-weight: 900;
        margin-left: 65px;
    }
    .card-title-hole {
        height: 80px; 
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .text-area-cliente {
        font-weight: 700;
        font-style: normal;
        font-size: 22px;
        color: #052449;
        line-height: 40px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .color-main-button {
        background-color: rgba(255, 27, 73, 1) !important;
        font-weight: 600;
    }
</style>