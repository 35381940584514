<template>
    <v-app>
        <v-main>
            <recovery-pass></recovery-pass>
        </v-main>
    </v-app>
</template>
<script>
import RecoveryPass from '../../components/RecoveryPass/RecoveryPass'
export default {
    name: 'RecoveryPassView',
    components: {
        RecoveryPass
    }
}
</script>