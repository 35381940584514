<template>
  <v-container class="pt-0 mt-0">
    <div class="text-left">
      <v-btn text icon class="pl-0 ml-0 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <!-- <div v-if="carregandoReservas">
      <v-row class="justify-center px-0">
        <v-col cols="12" class="px-0">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      Carregando reservas...
    </div> -->

    <div v-if="reservas">
      <minhas-reservas-lista :reservas="reservas" />
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MinhasReservasLista from '@/components/Reserva/MinhasReservasLista'

export default {
  components: {
    MinhasReservasLista
  },
  props: [
  ],
  data: () => ({
    carregandoReservas: false,
    reservas: []
  }),
  computed: {
    ...mapState({
      userData: state => state.user.userData,
    }),
  },
  methods: {
    ...mapActions({
      carregarReservasAction: 'hotel/carregarReservasAction'
    }),
    voltar() {
      this.$router.go(-1)
    }
  },
  created () {

    //console.log('HOTEL QUARTOS VIEW', this.hotel)
    console.log('userData',this.userData.sucesso.id)
    //this.carregandoReservas = true
    this.carregarReservasAction(this.userData.sucesso.id)
      .then(reservas => {
        console.log('then reservas',reservas)
        this.reservas = reservas
        this.carregandoReservas = false
      })
      .catch(e => {
        console.log('MinhasReservasView ERROR on created()',e)
      })

  }
}
</script>