<template>
<v-app>
 <v-main>
        <v-container class="fill-height" fluid>
            <v-row
                justify="center"
            >
                <v-card class="elevation-1 card-login">
                    <div class="card-title-hole">
                        <div>
                            <v-img
                                lazy-src="../../assets/images/logo_vivermais_rgb_fundobranco.png"
                                max-height="53"
                                max-width="120"
                                src="../../assets/images/logo_vivermais_rgb_fundobranco.png"
                            ></v-img>
                        </div>
                        <strong class="text-area-cliente">CADASTRO DE USUÁRIO</strong>
                    </div>
                    <v-card-text>
                        <v-form v-model="valid" @submit.prevent="register" ref="form" lazy-validation>
                            <v-col lg="12" md="12" sm="12">
                                <v-text-field
                                    v-model="name"
                                    :rules="nameRules"
                                    label="Nome completo"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col lg="12" md="12" sm="12">
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    label="E-mail"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col lg="12" md="12" sm="12">
                                <v-text-field
                                    v-model="password"
                                    :rules="passwordRules"
                                    :type="show ? 'text' : 'password'"
                                    label="Senha"
                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                    required
                                    @click:append="show = !show"
                                ></v-text-field>
                            </v-col>
                            <v-col lg="12" md="12" sm="12">
                                <v-text-field
                                    v-model="password_confirmation"
                                    :rules="passwordConfirmationRules"
                                    :type="showConfirmPass ? 'text' : 'password'"
                                    label="Confirmar senha"
                                    :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                                    required
                                    @click:append="showConfirmPass = !showConfirmPass"
                                    
                                ></v-text-field>
                            </v-col>
                            <v-col lg="12" md="12" sm="12">
                                <v-btn 
                                    type="submit"
                                    class="ma-2 color-main-button white--text"
                                    block
                                    large
                                    :loading="loading"
                                    :disabled="loading"
                                >
                                    Registrar
                                </v-btn>
                                <v-col>
                                    <!-- Already user make login -->
                                    Já é cadastrado? 
                                    <router-link
                                        to="/login"
                                    >
                                        <a>Login</a>
                                    </router-link>
                                </v-col>
                            </v-col>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-container>
 </v-main>
</v-app>
</template>

<script>
// import { HTTP } from '../../api/http-common'
import axios from 'axios'
export default {
    data() {
        return {
            valid: false,
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            show: false,
            showConfirmPass: false,
            loading: false,
            nameRules: [
                v => !!v || 'Nome é um campo obrigatório!'
            ],
            emailRules: [
                v => !!v || 'E-mail é um campo obrigatório!',
                v => /.+@.+/.test(v) || 'O e-mail deve ser válido!'
            ],
            passwordRules: [
                v => !!v || 'Senha é um campo obrigatório!'
            ],
            passwordConfirmationRules: [
                (v) => !!v || 'Confirmar senha',
                (v) => v === this.password || 'Senhas diferentes.',
            ]
        }
    },
    methods: {
        register() {
            if (this.$refs.form.validate() === false) {
                return
            }
            this.loading = true
            let formData = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            }
            axios.post('register', formData).then(res => {
                if (res.status === 201 || res.status === 200) {
                    this.$router.push({ name: 'Login' })
                }
                this.loading = false
            }).catch(error => {
                console.error('Error -', error)
                this.loading = false
            })
        }
    }
}
</script>

<style lang="css" scoped>
    .card-login {
        width: 400px;
        padding: 30px 45px;
    }
    .text-card-align {
        font-weight: 900;
        margin-left: 65px;
    }
    .card-title-hole {
        height: 80px; 
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .text-area-cliente {
        font-weight: 700;
        font-style: normal;
        font-size: 22px;
        color: #052449;
        line-height: 40px;
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .color-main-button {
        background-color: rgba(208, 0, 110, 1) !important;
        font-weight: 600;
    }
</style>